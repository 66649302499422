import {useState, useEffect, useContext} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CommonService from '../../services/CommonService'
import { IssuanceDetailContext } from '../../UserContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UserContext'
import { toast } from 'react-toastify'
import CurrencyInput from '../formElements/CurrencyInput'
import { runValidations, validateFormFields } from '../../utils'
import TooltipComponent from '../../common/Tooltip'

const IssuerDetails = (props) => {
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [issuer, setIssuerDetails] = useState(false);


    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuerDetailsByIssuerId",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : issuanceDetail.issuerId}
        };
    
        FinalSaleReportService.getIssuerDetails(obj)
          .then((result) => {
            setIssuerDetails(result.data.responses[0]);
          });
      }, [issuanceDetail.issuerId]);
    
    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" value={issuer.contactAddressLine1} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" value={issuer.contactAddressLine2} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" value={issuer.contactAddressLine3} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" value={issuer.contactAddressLine4} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" value={issuer.contactCityName} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" value={issuer.contactStateCode} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" value={issuer.contactZipCode} readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const Issuance = (props) => {
    const { markRoosLocalObligDetails } = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const user = useContext(UserContext);

    const validateForm = (e) => {
        e.preventDefault();
        if(stateValues.reserveMinBalInd === 'Y' && stateValues.reserveMinBalAmt === ''){
            toast.error('Reserve Fund Minimum Balance Amount is mandatory if the Reserve Fund Minimum Balance selected is Yes');
            return;
        }

        if(stateValues.authorityReserveFundInd === 'Y' && stateValues.percentReservedFund === undefined){
            toast.error('Percent of Reserve Fund  is mandatory if the part of Authority Reserve Fund selected is Yes');
            return;
        }
        
        let form = e.target.closest('form#issuanceForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "updateMKRRoosLocalObligReserve",
                hdrStruct: null,
                queryParam: null,
                reqData: {  
                    "env" : "I",
                    "marksRoosLocalObligor":{
                        "reserveMinBalInd" : stateValues.reserveMinBalInd,
                        "reserveMinBalAmt" : stateValues.reserveMinBalAmt,
                        "authorityReserveFundInd" : stateValues.authorityReserveFundInd,
                        "percentReservedFund" : stateValues.percentReservedFund,
                        "marksRoosLocalObligorId" : markRoosLocalObligDetails.marksRoosLocalObligorId,
                        "totalIssuanceCostAmt": stateValues.totalIssuanceCostAmt,
                        "lastUpdateUser": CommonService.getUserName(user),
                        "emailId": user
                    }
                },
            };
      
            FinalSaleReportService.updateMKRRoosLocalObligReserve(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    props.updateMkrLocalObligorDetails(result.data.responses[0]);
                    toast.success("Issuance Details updated successfully!");
                }
                
            });
            props.openWidget('fundBalance');
        }
    }

    const [stateValues, setStateValues] = useState({
        reserveMinBalInd : markRoosLocalObligDetails.reserveMinBalInd,
        reserveMinBalAmt: markRoosLocalObligDetails.reserveMinBalAmt,
        authorityReserveFundInd: markRoosLocalObligDetails.authorityReserveFundInd,
        percentReservedFund: markRoosLocalObligDetails.percentReservedFund,
        totalIssuanceCostAmt: markRoosLocalObligDetails.totalIssuanceCostAmt
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="issuanceForm">
                <h3>Issuance</h3>
            <Row>
            <Col>
                {/* <div style={{float: 'right', marginTop: '10px'}}>
                    <button
                        type="button"
                        className="dk-blue-color link-button" 
                        onClick={()=>setShowIssuerDetails(true)}
                        >View Issuer details</button>
                </div> */}
                <label>Issuer Name</label>
                <Form.Control type="text" value={issuanceDetail.issuerName} disabled/>
            </Col>
            </Row>
            
            <Row>
            <Col>
                <label>Issue Name</label>
                <Form.Control type="text" value={issuanceDetail.issueName} disabled/>
            </Col>
            </Row>
            <Row>
            <Col>
                <label>Project Name</label>
                <Form.Control type="text" value={issuanceDetail.projectName} disabled/>
            </Col>
            </Row>
            <Row>
                <Col>
                    <label>Date of Bond Issue/Loans
                    <TooltipComponent
                        title="This is the actual sale date of the Marks Roos Local Obligator."
                         id="actual-sale-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                    </TooltipComponent>
                    </label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled/> 
                </Col>
                <Col>
                    <label>Settlement Date</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled/> 
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Original Principal Amount</label>
                    <Form.Control type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled/>
                </Col>
                <Col>
                    <label>Date of Filing</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(markRoosLocalObligDetails.filingDate)} disabled/>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div className="control">
                <CurrencyInput 
                placeholder="0.00" 
                label="Total Issuance Costs" 
                name="totalIssuanceCostAmt" 
                type="text" 
                value={stateValues.totalIssuanceCostAmt ? stateValues.totalIssuanceCostAmt  : ""}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.totalIssuanceCostAmt} 
                validations={[!markRoosLocalObligDetails.isFirstYear && "required", "max:999999999999.99"]}
                isrequired={!markRoosLocalObligDetails.isFirstYear}
                disabled={markRoosLocalObligDetails.isFirstYear}
                helpText="In the initial report, the total cost of issuance for the bond issue (including underwriter's discount, bond counsel and financial advisor fees, printing, insurance and ratings) were paid from the bond proceeds. These costs are only to be reported on the first filing date, and this item left blank in subsequent reports."
                />
                </div>
                </Col>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Cost of Issuance from Report of Final Sale" 
                        name="totalIssuanceAmt" 
                        type="text" 
                        defaultValue={issuanceDetail.totalIssuanceAmt ? issuanceDetail.totalIssuanceAmt  : ""}
                        validations={["max:999999999999.99"]}
                        disabled
                        />
                    </div>
                </Col>
            </Row>
            <div className="control">
                <label>Name of Authority that purchased debt</label>
                <Form.Control type="text" value={issuanceDetail.authorityName} disabled/>
            </div>
            <Row>
                <Col md={6}>
                <label>Date of Authority Bond(s) Issuance
                    <TooltipComponent
                        title="Date of the Authority Bond(s) Issuance."
                         id="authority-bond"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                    </TooltipComponent>
                </label>
                <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.authorityBondIssuanceDate)} disabled/>
                </Col>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Costs of Issuance from Authority Report of Final Sale" 
                        name="authorityTotalIssuanceCost" 
                        type="text" 
                        defaultValue={issuanceDetail.authorityTotalIssuanceCost ? issuanceDetail.authorityTotalIssuanceCost  : ""}
                        validations={["max:999999999999.99"]}
                        disabled
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div>
                    <label>Is the Reserve Fund held at Authority level?</label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="reserveMinBalInd" id="reserveMinBalInd_Y" checked={stateValues.reserveMinBalInd === "Y"} label="Yes" value="Y" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="reserveMinBalInd" id="reserveMinBalInd_N" checked={stateValues.reserveMinBalInd === "N" } label="No" value="N" data-validations="required" onChange={(e)=>handleChange(e)}/>
                    </div>
 
                </div>
                </Col>
            </Row>
            <Row>
                < Col md={6}>
                <div className="control">

                <CurrencyInput 
                placeholder="0.00" 
                label="Reserve Fund Minimum Balance Amount" 
                name="reserveMinBalAmt" 
                type="text" 
                value={stateValues.reserveMinBalAmt}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                validations={["required", "max:999999999999.99"]}
                />
{/* 
                    <label>Reserve Fund Minimum Balance Amount</label>
                    <Form.Control type="text" value={stateValues.reserveMinBalAmt} data-validations="required" onChange={(e)=>handleChange(e)} name="reserveMinBalAmt" /> */}

                </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div>
                    <label>Part of Authority Reserve Fund</label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="authorityReserveFundInd" id="authorityReserveFundInd_Y" checked={stateValues.authorityReserveFundInd === "Y"} label="Yes" value="Y" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="authorityReserveFundInd" id="authorityReserveFundInd_N" checked={stateValues.authorityReserveFundInd === "N" } label="No" value="N" data-validations="required" onChange={(e)=>handleChange(e)}/>
                    </div>
 
                </div>
                </Col>
            </Row>
            <Row>
                < Col md={6}>
                <div className="control">
                <CurrencyInput 
                placeholder="0.00" 
                label="Percent of Reserve Fund" 
                name="percentReservedFund" 
                type="text" 
                value={stateValues.percentReservedFund}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                validations={["max:99.99"]}
                />

                </div>
                </Col>
            </Row>
            
            
            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </form>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)}/>}
        </Card>
    )
}

export default Issuance
